import React from 'react';
import { FormattedMessage } from 'react-intl';
import '../style/who-we-are.css';
import { CardImg } from '../components/common/CardImg';
import ImgHomePageRightHandPart from '../resources/map-far-right-hand-part.webp';
import { Card } from '../components/common/Card';
import ImgCoffee from '../resources/area-coffee-2.webp';
import ImgCoworkingInterior from '../resources/area-coworking-interior.webp';
import ImgOffice from '../resources/area-office-2.webp';
import ImgOfficeInterior from '../resources/area-office-interior.webp';
import { Img } from '../components/img/Img';
import { Href } from '../components/button/Href';
import { J_URL } from '../utils/const';

export function Offices() {
    return (
        <div className="description-page">
            <CardImg
                title="Les espaces publics et privés"
                subtitle="Comment ça marche ?"
                links={[
                    {
                        to: '/village',
                        label: 'Découvrir Jamespot Land ✨',
                    },
                ]}
                img={ImgHomePageRightHandPart}
                alt="Partie droite du village comprenant les bureaux et la zone de détente : la plage"
            />

            <Card description="Jamespot Land est un métavers professionnel dédié à la collaboration composé d’espaces publics et privés. Vous avez du mal à faire la distinction entre les deux une fois dans le métavers ? Voici quelques précisions pour vous aider à y voir plus clair !" />

            <h2 className="title-sea-blue">Les espaces publics</h2>

            <div className="max-width grid margin-h grid-reverse left">
                <div>
                    <p>
                        Les espaces publics sont accessibles par tous les utilisateurs de Jamespot Land quels qu’ils
                        soient.
                    </p>
                    <p>
                        Vous n’avez pas besoin d’une plateforme Jamespot pour accéder à Jamespot Land : notre métavers
                        est ouvert à tout le monde !
                    </p>
                    <p>
                        Cliquez sur le bouton « Rentrer dans J Land » ou sélectionnez la zone de votre choix sur la
                        carte pour accéder directement au métavers de Jamespot.
                    </p>
                </div>
                <Img src={ImgCoffee} height="400" alt="Café convivial pour échanger autour d'une boisson chaude" />
            </div>

            <div className="max-width grid margin-h left">
                <div>
                    <p>De nombreux espaces s’ouvrent alors à vous pour une multitude d’usages différents :</p>
                    <ul>
                        <li>Explorez le monde de Jamespot Land avec votre avatar.</li>
                        <li>Échangez avec les autres utilisateurs via la visioconférence.</li>
                        <li>Accédez à l’amphithéâtre pour assister à des présentations et des événements publics.</li>
                        <li>
                            Accédez aux zones de travail comme l’espace de coworking et le café pour travailler avec vos
                            collaborateurs et vos partenaires externes.
                        </li>
                        <li>Rencontrerzet échanger avec les membres de l’équipe Jamespot au sein de notre QG.</li>
                    </ul>
                </div>
                <Img
                    src={ImgCoworkingInterior}
                    height="400"
                    alt="Coworking à l'ambiance studieuse pour travailler en groupe"
                />
            </div>

            <div className="center">
                <Href to="/village" variant="orange" round outline>
                    <FormattedMessage id="DISCOVER_JLAND" />
                </Href>
            </div>

            <div className="bg-grey-0 private-space">
                <h2 className="title-sea-blue">L’espace privé de votre organisation</h2>

                <div className="max-width grid grid-reverse margin-h left">
                    <div>
                        <p>
                            Vous souhaitez collaborer avec vos équipes dans Jamespot Land sans prendre le risque d’être
                            déranger par d’autres utilisateurs ? Profitez d’un espace privé qui n’appartient qu’à vous
                            et vos équipes avec les bureaux Jamespot Land !
                        </p>
                        <p>
                            Les bureaux constituent l’espace privé de Jamespot Land. Chaque organisation détentrice
                            d’une ou plusieurs plateformes Jamespot bénéficie gratuitement de ses propres bureaux.
                            Chacun de ces espaces est accessible et personnalisable uniquement par les membres de
                            l’organisation à laquelle ils sont rattachés.
                        </p>
                    </div>
                    <Img src={ImgOffice} height="350" alt="Bureaux privés pour travailler avec ses équipes" />
                </div>

                <div className="max-width grid margin-h left">
                    <div>
                        <p>
                            Les utilisateurs qui bénéficient de bureaux privés ont également accès à tous les bâtiments
                            publics du métavers, en plus de pouvoir passer en quelques clics de leur plateforme Jamespot
                            à leurs bureaux numériques et inversement grâce au Airspot.
                        </p>
                        <p>
                            Adoptez la solution collaborative Jamespot, aménagez vos propres locaux numériques et
                            retrouvez le plaisir de la collaboration en temps réel grâce à Jamespot Land !
                        </p>
                    </div>
                    <Img src={ImgOfficeInterior} height="350" alt="Bureaux privés pour travailler avec ses équipes" />
                </div>
            </div>

            <div className="center">
                <Href to={J_URL.APPSTORE} variant="orange" round outline>
                    <FormattedMessage id="DISCOVER_OFFERS" />
                </Href>
                <Href to={J_URL.LAUNCH_OFFICE} variant="orange" round>
                    <FormattedMessage id="CREATE_MY_VIRTUAL_OFFICE" />
                </Href>
            </div>
        </div>
    );
}
