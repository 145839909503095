import React from 'react';
import { Connect } from '../components/Connect';
import { UserContext } from '../context/UserContext';
import { getMyPlatforms } from '../utils/api';
import { Link } from 'react-router-dom';
import type { ServerMaps } from '../utils/types';
import { FormattedMessage, useIntl } from 'react-intl';
import logo from '../resources/logo_jamespot_blanc.webp';
import logoLand from '../resources/logo_jamespot_land_blanc.webp';
import '../style/airspot.css';
import { Skeleton } from '../components/common/Skeleton';
import { J_URL } from '../utils/const';
import { Href } from '../components/button/Href';
import { PUBLIC_MAPS } from '../utils/constant';

export type AirSpotProps = {
    showPrivate?: boolean;
    showPublic?: boolean;
};

export function AirSpot({ showPrivate, showPublic }: AirSpotProps) {
    const intl = useIntl();

    const [userContextObject] = React.useContext(UserContext);
    const [loading, setLoading] = React.useState(true);
    const [platforms, setPlatforms] = React.useState<ServerMaps[]>([]);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (userContextObject.user) {
                getMyPlatforms()
                    .then((platforms) => {
                        setPlatforms(platforms);
                        setLoading(false);
                    })
                    .catch(() => setLoading(false));
            }
        }, 0);
        return () => {
            clearTimeout(timer);
        };
    }, [userContextObject.user]);

    const title = intl.formatMessage({ id: 'AIRSPOT_TITLE' });
    const firstWordsTitle = title.split(' ').slice(0, -1).join(' ');
    const lastWordTitle = title.split(' ').splice(-1);

    return (
        <>
            <main id="airspot">
                <div id="column-left-fixed">
                    <div id="column-left-relative">
                        <img className="top-img" src={logoLand} alt={intl.formatMessage({ id: 'JLAND_LOGO_ALT' })} />
                        <p>
                            <FormattedMessage id="TITLE_COMPLEMENT" />
                        </p>
                        <Link to="/">
                            <FormattedMessage id="GO_TO_HOME" />
                        </Link>
                        <span style={{ flexGrow: 1 }} />
                        <span id="made-with-love">
                            <FormattedMessage id="MADE_WITH_LOVE_BY" />
                            <a href={J_URL.JAMESPOT} target="_blank" rel="noreferrer">
                                <img src={logo} alt={intl.formatMessage({ id: 'JAMESPOT_LOGO_ALT' })} />
                            </a>
                        </span>
                    </div>
                </div>
                <div id="column-right">
                    <h1>
                        {firstWordsTitle} <span>{lastWordTitle}</span>
                    </h1>
                    <p className="go-to-home">
                        <FormattedMessage id="DESCRIPTION" />
                    </p>
                    <div id="destinations-all">
                        {showPublic && (
                            <div id="destinations-public">
                                {PUBLIC_MAPS.filter((map) => map.showAirSpot).map((map) => (
                                    <Link
                                        className="destination"
                                        key={map.href}
                                        to={map.href}
                                        style={{ '--map-color': map.color } as any}>
                                        <p className="title">
                                            <FormattedMessage id={`PUBLIC_MAP_${map.label}_TITLE`} />
                                        </p>
                                        <p className="description">
                                            <FormattedMessage id={`PUBLIC_MAP_${map.label}_DESCRIPTION`} />
                                        </p>
                                    </Link>
                                ))}
                            </div>
                        )}
                        <div id="destinations-private">
                            {userContextObject.user === undefined && <Connect />}
                            {loading &&
                                userContextObject.user &&
                                showPrivate &&
                                Array.from(Array(3).keys()).map((i) => <Skeleton key={i} />)}
                            {userContextObject.user &&
                                showPrivate &&
                                platforms.map((platform) => (
                                    <div
                                        className="destination"
                                        key={platform.dbName}
                                        style={{ '--map-color': 'turquoise' } as any}>
                                        <p className="title">{platform.nom}</p>
                                        <a
                                            href={
                                                'https://' + platform.domain + '/?action=goOAuth&serviceName=myjamespot'
                                            }>
                                            <FormattedMessage id={`PRIVATE_MAP_MY_PLATFORM`} />
                                        </a>
                                        <br />
                                        <Link to={`/office/${platform.domain}`}>
                                            <FormattedMessage id={`PRIVATE_MAP_MY_OFFICE`} />
                                        </Link>
                                        {platform.maps.map((map) => (
                                            <React.Fragment key={map.uri}>
                                                <br />
                                                <Link to={`/map/${platform.domain}/${map.uri}`}>{map.title}</Link>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                ))}
                            <div className="destination new-office">
                                <p className="title">Envie d'espace ? 🏢</p>
                                <p>Créer vos bureaux privés en quelques clics !</p>
                                <Href to={J_URL.LAUNCH_OFFICE} variant="purple" outline>
                                    <FormattedMessage id="CREATE_MY_VIRTUAL_OFFICE" />
                                </Href>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
