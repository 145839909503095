import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import logo from '../resources/logo_jamespot_land_blanc.webp';
import { J_URL } from '../utils/const';

export const Footer = () => {
    const intl = useIntl();

    return (
        <footer>
            <div className="footer-wrapper">
                <Link to="/">
                    <img src={logo} alt={intl.formatMessage({ id: 'JLAND_LOGO_ALT' })} />
                </Link>
                <div>
                    <span>
                        <Link to="world-of-jland">
                            <FormattedMessage id="LINK_WORLD_OF_JLAND" />
                        </Link>
                    </span>
                    <span className="footer__links__separators">.</span>
                    <span>
                        <Link to="/offices">
                            <FormattedMessage id="LINK_OFFICES" />
                        </Link>
                    </span>
                    <span className="footer__links__separators">.</span>
                    <span>
                        <Link to="who-we-are">
                            <FormattedMessage id="LINK_WHO_WE_ARE" />
                        </Link>
                    </span>
                    <span className="footer__links__separators">.</span>
                    <span>
                        <a href={J_URL.JAMESPOT} target="_blank" rel="noreferrer">
                            Jamespot
                        </a>
                    </span>
                    <span className="footer__links__separators">.</span>
                    <span>
                        <Link to="cgu">
                            <FormattedMessage id="LINK_CGU" />
                        </Link>
                    </span>
                    <span className="footer__links__separators">.</span>
                    <span>
                        <Link to="politique-de-confidentialite">Politique de confidentialité</Link>
                    </span>
                    <span className="footer__links__separators">.</span>
                    <span>
                        <Link to="gestion-des-cookies">Gestion des cookies</Link>
                    </span>
                </div>
            </div>
        </footer>
    );
};
