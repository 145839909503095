import { CardImg } from '../components/common/CardImg';
import ImgHomePageLeftHandPart from '../resources/map-left-hand-part.webp';
import AreaVillage from '../resources/area-village.webp';
import AreaSea from '../resources/area-sea.webp';
import AreaAmphitheater from '../resources/area-amphitheater.webp';
import AreaAirspot from '../resources/area-airspot.webp';
import AreaCoworking from '../resources/area-coworking.webp';
import AreaCoffee from '../resources/area-coffee.webp';
import AreaQgJamespot from '../resources/area-qg-jamespot.webp';
import AreaOffice from '../resources/area-office.webp';
import '../style/who-we-are.css';
import '../style/world-of-jland.css';
import { Card } from '../components/common/Card';
import { DiscoverJLandBanner } from '../components/common/DiscoverJLandBanner';
import { J_URL } from '../utils/const';

const AREAS = [
    {
        img: AreaVillage,
        label: 'Le village',
        description:
            'Le lieu où tous les grands esprits se rencontrent ! Le village est la place centrale de Jamespot Land où tous les professionnels peuvent se retrouver pour faire connaissance et échanger. Retrouvez vos collaborateurs, vos partenaires et faites la connaissance d’autres professionnels dans un endroit entièrement dédié à la collaboration.',
    },
    {
        img: AreaSea,
        label: 'La mer',
        description:
            'Ah, la mer et ses vagues ! Rien de mieux pour se ressourcer, et le métavers ne fait pas exception ! Le bord de mer et son atmosphère douce et reposante sont tout désignés pour vous éloigner quelques instants du bourdonnement de la ville et prendre le temps de vous concentrer sur l’essentiel.',
    },
    {
        img: AreaAmphitheater,
        label: 'L’amphithéâtre',
        description:
            'Pour réussir une présentation, le cadre est un élément primordial ! De la présentation de votre dernier produit jusqu’à vos prochains webinars, réunissez votre audience au sein de l’amphithéâtre de Jamespot Land pour réaliser toutes vos présentations. THE place pour des annonces qui resteront dans les mémoires !',
    },
    {
        img: AreaAirspot,
        label: 'AirSpot',
        description:
            'Jamespot Land regorge de lieux pour échanger et collaborer avec l’ensemble des acteurs de votre organisation. Encore faut-il pouvoir s’y rendre rapidement ! Le Air Spot est là pour ça, et vous permet de rejoindre la zone J Land, la plateforme Jamespot ou la réunion de votre choix ! Prenez un ticket, embarquez dans le Air Spot et profitez du paysage jusqu’à votre prochaine destination ! 🎈',
    },
    {
        img: AreaCoworking,
        label: 'Le co-working',
        description:
            'Le lieu où les idées fusent sans cesse et où les projets prennent vie ! Comme son nom l’indique très bien, l’espace de co-working est l’endroit public où tous les professionnels peuvent se retrouver à n’importe quel moment pour travailler en duo ou en équipe ! Attention aux interruptions impromptues !',
    },
    {
        img: AreaCoffee,
        label: 'Le café',
        description:
            'Il se passe toujours quelque chose au café de Jamespot Land ! Faire de nouvelles rencontres, s’isoler pour avancer sur son dernier projet ou tout simplement profiter de la musique ambiante pour faire une pause. Un lieu dédié au meilleur de la détente et de la concentration : un café pour lancer la journée, ça vous tente ? ☕',
    },
    {
        img: AreaQgJamespot,
        label: 'Le QG Jamespot',
        description:
            'Vous avez une question ? Envie d’en apprendre plus sur Jamespot ou bien de participer à l’un de nos événements ? Pas besoin de faire le tour de Jamespot Land pour trouver ce dont vous avez besoin, nos équipes sont joignables à tout moment via le QG Jamespot ! Pour nous rencontrer, rendez-vous au bâtiment en forme de J (et même pas besoin de toquer !)',
    },
    {
        img: AreaOffice,
        label: 'Les bureaux',
        description:
            'La ou les plateformes privées de votre organisation tout simplement ! Ces dernières ne sont accessibles que par vos collaborateurs. De véritables locaux d’entreprises aménagés par vos soins dans le métavers : le plaisir du présentiel directement dans le numérique !',
    },
];

export function WorldOfJLand() {
    return (
        <div className="description-page">
            <CardImg
                title="Le monde de Jamespot Land"
                subtitle="Où allez vous travailler aujourd’hui ?"
                links={[
                    {
                        label: 'Découvrir Jamespot Land ✨',
                        to: '/village',
                    },
                ]}
                img={ImgHomePageLeftHandPart}
                alt="Partie gauche du village comprenant le co-working et l'amphithéâtre'"
            />

            <Card
                title="Découvrez le monde JLand"
                description="Jamespot Land est un vaste monde numérique composé de plusieurs zones à explorer, chacune unique en son genre et conçue pour permettre à vos collaborateurs de mieux échanger et collaborer au quotidien. Certaines sont accessibles à tous les utilisateurs de Jamespot Land quels qu’ils soient tandis que d’autres sont privées, directement rattachées à des plateformes Jamespot et nécessitent des droits d’accès pour être explorer. Voici un tour d’horizon des endroits qui n’attendent que vous au sein de Jamespot Land !"
                links={[
                    { label: 'DISCOVER_JLAND', to: '/village' },
                    { label: 'CREATE_MY_VIRTUAL_OFFICE', to: J_URL.LAUNCH_OFFICE },
                ]}
            />

            <ul className="areas max-width">
                {AREAS.map(({ img, label, description }) => (
                    <li key={label}>
                        <img src={img} aria-hidden="true" alt="" />
                        <h3>{label}</h3>
                        <p>{description}</p>
                    </li>
                ))}
            </ul>

            <DiscoverJLandBanner />
        </div>
    );
}
